import React, { useEffect } from 'react';

const GoogleAd = ({ adUnitPath, size, divId }) => {
  useEffect(() => {
    const loadGoogleAds = () => {
      window.googletag = window.googletag || { cmd: [] };
      window.googletag.cmd.push(() => {
        try { 
          googletag
          .defineSlot(adUnitPath, size, divId)
          .addService(googletag.pubads()); 
        } catch (error) {
          console.error('error in googletag', error)
        }
        googletag.pubads().enableSingleRequest();
        googletag.enableServices();
      });
      try { 
        googletag.cmd.push(() => { googletag.display(divId); }); 
      } catch (error) {
        console.error('error in googletag cmd', error)
      }
    };

    loadGoogleAds();
  }, [adUnitPath, size, divId]);

  return <div id={divId} style={{ minWidth: size[0], minHeight: size[1] }}></div>
};

export default GoogleAd;